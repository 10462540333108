var homePage ={
	 data: function() {return {		
	
	  }}
,
props: {

},
computed:{
},
methods:{

},
mounted:function(){
	
},
  template: `
<div>
<h1>World class solutions that scale</h1>
<hr>
<h3>Leap ahead to the cutting edge with a trusted partner and unlock the highest levels your business can achieve.</h3>
</div>
  `
};

export default homePage;