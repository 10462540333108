var blogPage ={
	 data: function() {return {		
		articles:{},
		bucket:'https://com-scaledlabs-articles.s3.amazonaws.com',
		currentArticle:null	,
	  }}
,
props: {
	
},
computed:{
	byCategory:function(){
		var pl=_.pluck(this.articles,'categories');
		return _.flatten(pl);
	},
	byTitle:function(){
		return _.map(this.articles,function(t){
			var title= t.title.toLowerCase();
			return title.replace(/ /g,'-');
		});
	},
	showAll:function(){
		return !this.$route.params.title;
	},
	showArticle:function(){
		return this.$route.params.title !=null && this.currentArticle!=null;	
	},
	sortedArticles:function(){
		return _.sortBy(this.articles,function(f){return moment(f.date)});
	}

},
methods:{
	getArticle:function(name)
	{
		var self=this;
		$.ajax({
			url:this.bucket+'/'+name,
			crossDomain:true,
			success:function(data){
			
				var d= JSON.parse(data);
				self.articles.push(d);
				if (self.$route.params.title !=null && d.title.toLowerCase().replace(/ /g,'-') === self.$route.params.title){
					self.currentArticle=d;}
			}
		})
	},
	substr:function(obj){
		if (obj)
			return obj.substring(0,100)+'...';
		return null;
	},
	getArticleLink:function(obj){
		if (obj)
			return 'Articles/'+obj.title.toLowerCase().replace(/ /g,'-');
		return null;
	}
},
created:function(){
	var self=this;
$.ajax({
            url: this.bucket+'?list-type=2',
			crossDomain: true,
            success: function(xml) {
			self.articles=[];
                $(xml).find('Contents').each(function(){
                    $(this).find("Key").each(function(){
                        self.getArticle( $(this).text());                   
                    });
                });
            }
})},
mounted:function(){
	
},
  template: `
<div>
<div v-if="showAll">
	<h1>Articles</h1>
	<div v-for="article in sortedArticles">
		<h3><a v-bind:href="getArticleLink(article)">{{article.title}}</a> </h3>
		{{article.date}}<br>
		<span v-html="substr(article.content)"></span>
	</div>
	</div>
	<div v-if="showArticle">
		<h1>{{currentArticle.title}}</h1>
		{{currentArticle.date}}<br>
		<span v-html="currentArticle.content"></span>
	</div>

</div>
  `
};

export default blogPage;