var servicePage ={
	 data: function() {return {		
	
	  }}
,
props: {

},
computed:{
},
methods:{

},
mounted:function(){
	
},
  template: `
<div>
	<h1>Services</h1>
	<hr>
	<table class="table table-responsive-stack table-borderless">
	<tr>
		<td>
			<h4>SAFe® – Scaled Agile Framework</h4>
			SAFe® is the world leading framework for enterprise agility. We have developed an innovative and highly effective implementation strategy within the framework, designed to quickly reap the benefits without missteps. 
		</td>
		<td>
			<h4>Digital Platform Architecture</h4>
			Leverage world class infrastructure, middleware, and application frameworks that enable even the simplest services to scale effortlessly. We want to help you eliminate your technical debt and modernize your technology stack.
		</td>
		<td>
			<h4>Development Transformation</h4>
			Work with our team on your journey to transition your software development practices and architectural approach to a lean mindset and flow. Our unique agile approach realizes benefits early and sustains growth.
		</td>
		<td>
			<h4>DevOps Design and Implementation</h4>
			DevOps is more than a set of tools – it’s a disciplined culture. Demystify the actual implementation of DevOps away from academia and into a structured, actionable roadmap with our team. De-risk your technology and let it grow
		</td>
	</tr>
	</table>
</div>
  `
};

export default servicePage;