import blogPage from "./blog.js";
import homePage from "./home.js";
import productPage from "./products.js";
import servicePage from "./services.js";
import contactPage from "./contact.js";
import legalPage from "./legal.js";

var vueApp =Vue.createApp({
	  el: '#vueapp',
	 data: function(){ return {
		}},
	computed:{
		pageTitle:function(){
			document.title= this.$route.meta.title;
		}
		},
	methods:{
		move:function(where){
			this.$router.push(where);
		}
	},
	created:function(){
			
        
	}
	});
	
const routes = [
{path: '/products', component: productPage, meta:{title:'scaledLabs - Products'}},
{path: '/services', component: servicePage, meta:{title:'scaledLabs - Services'}},
{path: '/articles', component: blogPage, meta:{title:'scaledLabs - Articles'}},
{path: '/legal', component: legalPage, meta:{title:'scaledLabs - Legal'}},
{path: '/articles/:title', component: blogPage, meta:{title:'scaledLabs - Articles'}},
{path: '/contact', component: contactPage, meta:{title:'scaledLabs - Contact'}},
  { path: '/:pathMatch(.*)*', name: 'not-found', component: homePage, meta:{title:'scaledLabs'} },
	]
const router =  VueRouter.createRouter({
  routes,
history: VueRouter.createWebHistory()
});
vueApp.component('productPage',productPage);
vueApp.component('servicePage',servicePage);
vueApp.component('blogPage',blogPage);
vueApp.component('contactPage',contactPage);
vueApp.component('homePage',homePage);
vueApp.component('legalPage',legalPage);
vueApp.use(router);

vueApp.mount('#vueapp');