var productPage ={
	 data: function() {return {		
	
	  }}
,
props: {

},
computed:{
},
methods:{

},
mounted:function(){
	$('.carousel').carousel({
		interval:3000
	});
},
  template: `
<div>
	<h1>Products</h1>
	<hr>
	<h2>Digital PI Planning</h2>
	<div>
	Our solution to the challenge created by SAFe Program Increment (PI) planning with geographically distributed teams. 100% integrated with Atlassian’s JIRA – the most powerful issue tracking platform designed for agile teams – Digital PI Planning allows your teams to interact in real time with a virtual Program Board, Team Boards, Risk ROAM Board, and more – from any device, including their handhelds.
	</div>

<div style="width:75%; margin:0 auto" >	
	<div id="carousel" class="carousel slide carousel-fade" data-ride="carousel">
	 <ol class="carousel-indicators">
	    <li data-target="#carousel" data-slide-to="0" class="active"></li>
	    <li data-target="#carousel" data-slide-to="1"></li>
	    <li data-target="#carousel" data-slide-to="2"></li>
	  	<li data-target="#carousel" data-slide-to="3"></li>
	  	<li data-target="#carousel" data-slide-to="4"></li>
	  </ol>
	  <div class="carousel-inner">
	    <div class="carousel-item active">
	      <img class="d-block w-100" src="./images/agendaSnap.png" alt="Agenda view">
	    </div>
	    <div class="carousel-item">
	      <img class="d-block w-100" src="./images/featureSnap.png" alt="Features and WSJF">
	    </div>
	    <div class="carousel-item">
	      <img class="d-block w-100" src="./images/programBoard.png" alt="Program board view">
	    </div>
	    <div class="carousel-item">
	      <img class="d-block w-100" src="./images/boardView1.png" alt="Team board view">
	    </div>
	    <div class="carousel-item">
	      <img class="d-block w-100" src="./images/Roamer.png" alt="Risk ROAM board view">
	    </div>
	  </div>
	</div>
</div>	
	<div>For more information on Digital PI Planning, please refer to our <a href="/Articles/using-digital-safe-by-scaledlabs">documentation</a> page.</div>
</div>
  `
};

export default productPage;