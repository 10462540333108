var contactPage ={
	 data: function() {return {		
	
	  }}
,
 data: function() {
		 return {		

		firstName:'',
        lastName:'',
		email:'',
		comment:''
	  }},
computed:{
},
methods:{
	submit:function(){
		$.ajax({
			type:"POST",
			url:"https://formspree.io/f/mwkwjaeq",
			data:{
				"message":this.comment,
				"_replyto":this.email,
				"firstName":this.firstName,
				"lastName": this.lastName
			}
		});
		;
	}
},
mounted:function(){
	
},
  template: `
<div>
	<h2>Contact</h2>
	<hr>
	<div>		
		<label>Name</label>
		<div class="row">
			<div class="col">			
				<input type="text" class="form-control" v-model="firstName"></input>
					<small class="text-muted">First</small>
			</div>
				
			<div class="col">			
				<input type="text" class="form-control" v-model="lastName"></input>
					<small class="text-muted">Last</small>
			</div>
		</div>

		<div>
			<label>Email</label>
			<input type="text" class="form-control" v-model="email" ></input>
		</div>
		<div>
			<label>Comment or Message</label>
			<textarea class="form-control" v-model="comment" ></textarea>
		</div>
		 
	</div>
	<hr>
	 <button class="btn btn-primary" @click="submit">Submit</button>
</div>
  `
};

export default contactPage;